<template>
  <div class="lpac-list__item" :class="'lpac-list__item--' + product.id">
    <article
      :id="id"
      class="lpac"
      :class="[
        product.contributionStatus == 1 ? 'lpac--regalar' : '',
        product.contributionStatus == 2 ? 'lpac--regalado' : '',
        product.contributionStatus == 3 ? 'lpac--reservado' : '',
      ]"
    >
      <!-- Label corner oferta -->
      <div class="lpac__label-oferta" v-show="product.liquidation">
        <span>¡Oferta!</span>
      </div>

      <!-- Imagenes y resevar  -->
      <section class="lpac__left">
        <!-- Imagen -->
        <!-- <div class="lpac__image" @click="productSelected"> </div> -->
        <picture class="lpac__image" @click="showImages = !showImages">
          <div class="lpac__share" v-show="canShare" @click.stop="shareProduct()">
            <i class="uil uil-share-alt"></i>
          </div>
          <div
            class="lpac__label-stock"
            v-if="product.contributionStatus != 2"
            v-show="product.quantityAvailable >= 1 && product.quantityAvailable <= 5"
          >
            ¡Solo quedan {{ product.quantityAvailable }}!
          </div>
          <div class="lpac__label-status" v-show="product.contributionStatus != 1">
            <span class="badge" :class="[statusColor]">{{
              statusText[product.contributionStatus]
            }}</span>
          </div>
          <img :src="$getImgUrl(product.photo)" alt />
        </picture>

        <!-- Reservar y cancelar DESKTOP -->
        <div
          class="lpac__reservar lpac__reservar--desk"
          v-show="product.contributionStatus == 1 && !(contributionsEnabledForProductPrice(product.price, list) && product.contributionAmount > 0)"
        >
          <button class="button button--light button--ico" @click="openBooking">
            <i class="uil uil-lock"></i> {{ $t("guest.reserve") }}
          </button>

          <!-- <button
            v-show="showCancelBooking()"
            @click="openCancelBooking"
            class="button button--light button--ico"
          >
            <i class="uil uil-lock"></i> Cancelar reserva
          </button> -->
        </div>

        <div
          class="lpac__reservado"
          v-show="isBlockedByReservation"
          @click="confirmBooking(false)"
        >
          <span class="link link--underline link--bold"> {{ $t("guest.reservedByYou") }} </span>
        </div>
        <div
          class="lpac__reservar lpac__reservar--desk"
          v-show="!isBlockedByReservation && product.contributionStatus == 3"
        >
          <button class="button button--light button--ico" @click="openCancelBooking">
            <i class="uil uil-unlock"></i> {{ $t("guest.cancelReservation") }}
          </button>
        </div>
      </section>

      <!-- Contenido del card  -->
      <aside class="lpac__right">
        <div class="lpac__product">
          <!-- Imprescindible  -->
          <div
            class="lpac__imprescindible"
            v-if="product.mustHave && product.contributionStatus !== 2"
          >
            <img src="../../../assets/img/ico/ico-heart-fill.svg" alt="" />
            {{ $t("guest.setAsMustHaveBy", { name: list.ownerName }) }}
          </div>

          <!-- Titulo  -->
          <h3 class="lpac__title" :id="id" @click="productSelected">
            {{ $utils.truncate(product.name, 60) }}
          </h3>

          <!-- Variables de producto: colores, tallas, etc.  -->
          <!-- <template v-if="retailerIsHelloBB">
            <span
              v-if="
                product.selectedVariantText &&
                product.selectedVariantText != 'Title: Default Title'
              "
              class="lpac__variables"
            >
              {{ product.selectedVariantText }}
            </span>
          </template> -->

          <!-- Consejos de venta  -->
          <div
            class="lpac__tips"
            v-if="retailerIsHelloBB && product.contributionStatus !== 2"
          >
            <div class="ecom-add-tips">
              <div class="ecom-add-tips__item">Envío gratis (pedidos +50€)</div>
              <div class="ecom-add-tips__item">Entrega en 24/48h</div>
            </div>
          </div>

          <!-- Mostrar si hay comentarios retailerIsHelloBB -->
          <template v-if="retailerIsHelloBB">
            <section class="lpac__comments" v-if="product.userComments">
              <div class="lpac-comments">
                <div class="lpac-comments__title">
                  {{ $t("guest.thereIsAComment", { name: list.ownerName } ) }}
                </div>
                <div class="lpac-comments__comment">
                  <div class="lpac-comments__content">
                    <template v-if="this.$mq === 'm992' || this.$mq === 'm1200'">
                      {{ $utils.truncate(product.userComments, 70) }}
                    </template>
                    <template v-else>
                      {{ $utils.truncate(product.userComments, 35) }}
                    </template>
                  </div>
                  <div
                    class="lpac-comments__more"
                    v-if="product.userComments.length > 70"
                    @click="showComment = !showComment"
                  >
                    <i class="uil uil-plus-circle" v-if="truncateComment"></i>
                    <i class="uil uil-minus-circle" v-else></i>
                  </div>
                </div>
              </div>
            </section>
          </template>

          <!-- Linea de precios  -->
          <div
            class="lpac__price"
            v-if="retailerIsHelloBB && product.contributionStatus != 2"
          >
            <div class="list-price">
              <div v-if="product.compareAtPrice === 0" class="list-price__regular">
                <bdi>{{ $utils.formatMoneyNoSpace(product.price) }}</bdi>
              </div>
              <!-- v-if producte amb descompte -->
              <div v-else class="list-price__sale">
                <del>{{ $utils.formatMoneyNoSpace(product.compareAtPrice) }}</del>
                <bdi>{{ $utils.formatMoneyNoSpace(product.price) }}</bdi>
              </div>
            </div>
          </div>
        </div>

        <!-- RETAILER HelloBB ///////////////////////// -->
        <!-- TODO, no mostrar .lpac__card si no te contingut  -->
        <section class="lpac__card" v-if="product.contributionStatus != 2">
          <aside class="list-card-add" v-if="retailerIsHelloBB">
            <div class="list-card-add__header">
              <span class="list-card-add__header-logo">
                <img src="../../../assets/img/logos/favicon.svg" alt="" />
              </span>
              <span class="list-card-add__header-title"> {{ $t("mylist.buyAtHelloBB") }} </span>
            </div>
            <div class="list-card-add__body">
              <div class="list-card-add__tips">
                <div class="ecom-add-tips ecom-add-tips--fs">
                  <div class="ecom-add-tips__item">{{ $t("mylist.easyReturn") }}</div>
                  <div class="ecom-add-tips__item">
                    Si compras este regalo
                    <strong>
                      {{ list.ownerName }} podrá acumular
                      {{ $utils.formatMoneyNoSpace((product.price / 1.21) * 0.05) }}.
                    </strong>
                  </div>
                </div>
              </div>

              <shopify-add-to-cart-button
                v-show="
                  product.shopifyId &&
                  (product.contributionStatus == 1 ||
                    (product.contributionStatus == 3 && !isBlockedByReservation))
                "
                :product="product"
                :comprarYa="true"
                :addToCartVisible="true"
                @opening="sendMixPanelInfo()"
                @confirmBooking="confirmBooking(true)"
                ref="shopifyButtonRef"
              ></shopify-add-to-cart-button>
            </div>
            <!-- <already-bought :product="product"></already-bought> -->
          </aside>

          <!-- EXTERNAL Product  -->
          <template v-if="!retailerIsHelloBB && !contributionsEnabledForProductPrice(product.price, list)">
            <aside
              class="list-card-add"
              v-if="
                product.contributionStatus == 1 ||
                (!isBlockedByReservation && product.contributionStatus == 3)
              "
            >
              <div class="list-card-add__header">
                <span class="list-card-add__header-title"> {{ $t("guest.youCanFindItIn") }} </span>
              </div>
              <div class="list-card-add__body">
                <product-retailer-links :product="product" @error="emitError"></product-retailer-links>
                <!-- <already-bought :product="product"></already-bought> -->
              </div>
            </aside>
          </template>

          <!-- Mostrar si hay comentarios EXTERNAL -->
          <template v-if="!retailerIsHelloBB">
            <section class="lpac__comments" v-if="product.userComments">
              <div class="lpac-comments">
                <div class="lpac-comments__title">
                  {{ $t("guest.thereIsAComment", { name: list.ownerName }) }}
                </div>
                <div class="lpac-comments__comment">
                  <div class="lpac-comments__content">
                    <template v-if="this.$mq === 'm992' || this.$mq === 'm1200'">
                      {{ $utils.truncate(product.userComments, 70) }}
                    </template>
                    <template v-else>
                      {{ $utils.truncate(product.userComments, 35) }}
                    </template>
                  </div>
                  <div
                    class="lpac-comments__more"
                    v-if="shouldShowMoreButton"
                    @click="showComment = !showComment"
                  >
                    <i class="uil uil-plus-circle"></i>
                  </div>
                </div>
              </div>
            </section>
          </template>
        </section>

        <!-- REGALAR NEW ////////////////////////////// -->

        <div
          class="lpac__desde"
          v-if="
            list.stripeAccountId &&
            !retailerIsHelloBB &&
            (product.contributionStatus == 1 ||
              (product.contributionStatus == 3 && !isBlockedByReservation)) &&
            product.contributionAmount == 0
          "
        >
          <aside class="list-price-desde">
            <span
              class="list-price-desce__desde"
              v-show="!product.isFree && product.productRetailers.length > 1"
            >
              desde
            </span>
            <div v-if="product.compareAtPrice === 0" class="list-price-desde__regular">
              <bdi>{{ $utils.formatMoneyNoSpace(product.price, currency) }}</bdi>
            </div>
            <div
              class="list-price-desde__info"
              v-show="!product.isFree && product.productRetailers.length > 1"
              v-tooltip="{
                html: true,
                content:
                  'El precio final dependerá de la tienda en la que ' +
                  list.ownerName +
                  ' lo compre.',
                handleResize: false,
                triggers: ['hover'],
                // placement: 'auto-end',
              }"
            >
              <i class="uil uil-info-circle"></i>
            </div>
          </aside>
        </div>

        <div
          class="lpac__desde"
          v-if="
            list.stripeAccountId &&
            !retailerIsHelloBB &&
            (product.contributionStatus == 1 ||
              (product.contributionStatus == 3 && !isBlockedByReservation)) &&
            product.contributionAmount > 0
          "
        >
          <aside class="list-price-aportado">
            <div class="list-price-aportado__label">{{ $t("guest.contribution.contributedSoFar").replace(":","") }}</div>
            <div class="list-price-aportado__main">
              <span class="list-price-aportado__current">
                {{ $t("guest.contribution.contributionStatus", { contributed: product.contributionAmount, goal: product.contributionGoalAmount }) }}
              </span>
              <span class="list-price-aportado__falta"
                >(Faltan
                {{
                  (product.contributionGoalAmount - product.contributionAmount).toFixed(2)
                }}
                €)</span
              >
            </div>
          </aside>
        </div>

        <template
          v-if="
            contributionsEnabledForProductPrice(product.price, list) &&
            !retailerIsHelloBB &&
            (product.contributionStatus == 1 ||
              (product.contributionStatus == 3 && !isBlockedByReservation))
          "
        >
          <ListProductRegalar :product="product" :list="list" />

          <!-- Modal checkout Stripe del regal  -->
          <!-- <ListProductRegalarCheckout
            :product="product"
            :list="list"
          ></ListProductRegalarCheckout> -->
        </template>

        <template>
          <!-- Reservar y cancelar movil -->
          <div
            class="lpac__reservar lpac__reservar--m"
            v-if="product.contributionStatus == 1 && !(contributionsEnabledForProductPrice(product.price, list) && product.contributionAmount > 0)"
          >
            <button class="button button--light button--ico" @click="openBooking">
              <i class="uil uil-lock"></i> {{ $t("guest.reserve") }}
            </button>
          </div>
          <div
            class="lpac__reservar lpac__reservar--m"
            v-if="product.contributionStatus == 3 && !isBlockedByReservation"
          >
            <button class="button button--light button--ico" @click="openCancelBooking">
              <i class="uil uil-unlock"></i> {{ $t("guest.cancelReservation") }}
            </button>
          </div>
        </template>
      </aside>
      <div
        class="lpac__footer"
        v-if="
          !(contributionsEnabledForProductPrice(product.price, list) && product.contributionAmount > 0)
          && (
            product.contributionStatus == 1
            || (product.contributionStatus == 3 && !isBlockedByReservation)
          )
        "
      >
        <already-bought :product="product"></already-bought>
      </div>
    </article>

    <!-- MODAL ZOOM  IMAGES -->

    <ModalSlider :show="showImages" @close="cancel()">
      <template v-if="product.photos.length <= 1">
        <picture class="modal-slider-simple">
          <img class="modal-slider-simple__img" :src="$getImgUrl(product.photo)" alt />
        </picture>
      </template>
      <template v-else>
        <div class="modal-slider-carousel">
          <VueSlickCarousel v-bind="sliderPhotos" :dots="false">
            <div
              class="modal-slider-carousel__item"
              v-for="photo in product.photos"
              :key="photo.id"
            >
              <div class="modal-slider-carousel__wrapper">
                <img
                  class="modal-slider-carousel__img"
                  :src="
                    retailerIsHelloBB
                      ? photo.path
                      : 'https://images.hellobb.net/' + photo.path
                  "
                  alt
                />
              </div>
            </div>
          </VueSlickCarousel>
          <div class="modal-slider-carousel__arrows"></div>
        </div>
      </template>
    </ModalSlider>

    <!-- MODAL COMENTARIS  -->
    <ModalDialog :show="showComment" @close="cancel()" target-class="modal__card--mini">
      <div class="flux-regalo">
        <div class="flux-regalo__title">
          {{ $t("guest.thereIsAComment", { name: list.ownerName }) }}
        </div>
        <div
          class="flux-regalo__msg flux-regalo__msg--left"
          v-html="product.userComments"
        ></div>
      </div>
    </ModalDialog>

    <!-- TODO: MODAL PRODUCTE RESERVAT-->
    <transition name="bbFace" @after-enter="showBookedModalContent = true">
      <div class="modal" v-show="showBookedModal" @click="close()">
        <transition name="bbUp" @after-leave="close()">
          <div v-show="showBookedModalContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back"></div>
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="close()"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="flux-regalo">
                  <div class="flux-regalo__title">
                    {{ $t("guest.reservation.identifyByEmail") }}
                  </div>

                  <div class="flux-regalo__msg">
                    {{ $t("guest.reservation.whatYouCanDo") }}
                  </div>

                  <div class="flux-regalo__form mb-0">
                    <div class="bb-form-item">
                      <div class="bb-form-label">{{ $t("guest.reservation.yourEmail") }}</div>
                      <input
                        type="email"
                        class="bb-form"
                        :placeholder='$t("guest.reservation.emailPlaceholder")'                        @focus="focused = true"
                        @blur="focused = false"
                        v-model="email"
                        :class="{
                          'bb-form--success': emailIsValid && !focused,
                          'bb-form--danger': emailHasError && !focused,
                        }"
                        required
                      />
                      <div class="bb-form-error" v-show="emailHasError && !focused">
                        {{ $t("guest.reservation.errorField") }}
                      </div>
                      <div class="bb-form-error" v-show="this.error">
                        {{ $t("guest.reservation.noReservation") }}
                      </div>
                    </div>
                    <loading-button v-if="loadingBooking"></loading-button>
                    <button
                      v-else
                      class="button button--primary button--block mar-t-1"
                      @click="next"
                      :disabled="disabled"
                    >
                      {{ $t("generic.next") }}
                    </button>
                  </div>

                  <div class="flux-regalo__actions"></div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <!-- TODO: MODAL PRODUCTE RESERVAT DESBLOQUEJAT -->
    <transition name="bbFace" @after-enter="showBookedModalUnlockedContent = true">
      <div class="modal" v-show="showBookedUnlockedModal" @click="close()">
        <transition name="bbUp" @after-leave="close()">
          <div v-show="showBookedModalUnlockedContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back"></div>
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="closeUnlocked()"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="flux-regalo">
                  <div class="flux-regalo__title">{{ $t("guest.reservation.welcome") }}</div>

                  <div class="flux-regalo__msg">{{ $t("guest.reservation.welcomeText") }}</div>

                  <div class="flux-regalo__form mb-0">
                    <loading-button v-if="loadingBooking"></loading-button>
                    <button
                      v-else
                      class="button button--primary button--block mar-t-1"
                      @click="closeUnlocked()"
                      :disabled="disabled"
                    >
                      {{ $t("generic.continue") }}
                    </button>
                  </div>

                  <div class="flux-regalo__actions"></div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <!-- MODAL RESERVAR PRODUCTE-->
    <transition name="bbFade" @after-enter="showBookingContent = true">
      <div class="modal" v-show="showBooking" @click="showBookingContent = false">
        <transition name="bbUp" @after-leave="closeBooking()">
          <div v-show="showBookingContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back" v-show="bookingStep > 2">
                  <img
                    @click="bookingStep--"
                    src="../../../assets/img/ico/ico-arrow-back.svg"
                    alt="Atras"
                  />
                </div>
                <div class="modal__title">
                  <slot name="titulo"></slot>
                </div>
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showBookingContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <FluxRegaloReservaStep1
                  v-show="bookingStep === 1"
                  :product="product"
                  :list="list"
                  @next="nextFirstStep"
                ></FluxRegaloReservaStep1>
                <FluxRegaloReservaStep2
                  v-show="bookingStep === 2"
                  @next="finishBooking()"
                ></FluxRegaloReservaStep2>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <!-- CANCEL MODAL-->
    <transition name="bbFade" @after-enter="showCancelContent = true">
      <div class="modal" v-show="showCancel" @click="showCancelContent = false">
        <transition name="bbUp" @after-leave="closeCancel()">
          <div v-show="showCancelContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back"></div>
                <div class="modal__title"></div>
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showCancelContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="card-success">
                  <div class="card-success__header">{{ $t("guest.reservation.doYouWantToCancel") }}</div>
                  <div class="card-success__content">
                    {{ $t("guest.reservation.cancelReservationAdvice") }}
                  </div>
                  <div
                    class="card-success__actions card-success__actions--vertical card-success__actions--50"
                  >
                    <loading-button class="" v-if="loadingCancelBooking"></loading-button>
                    <button v-else class="button button--primary" @click="cancelBooking">
                      {{ $t("guest.cancelTheReservation") }}
                    </button>
                    <button
                      class="button button--secondary"
                      @click="showCancelContent = false"
                    >
                      {{ $t("guest.reservation.keepReservation") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import ShopifyAddToCartButton from "../../shared/ShopifyAddToCartButton";
import ProductRetailerLinks from "./ProductRetailerLinks.vue";
import AlreadyBought from "./AlreadyBought.vue";
import LoadingButton from "../../../components/LoadingButton";
import ModalSlider from "../../../components/ui/ModalSlider.vue";
import ModalDialog from "../../../components/ui/ModalDialog.vue";
import FluxRegaloReservaStep1 from "./FluxRegaloReservaStep1.vue";
import FluxRegaloReservaStep2 from "./FluxRegaloReservaStep2.vue";
import ListProductRegalar from "./ListProductRegalar.vue";
import ListProductRegalarCheckout from "./ListProductRegalarCheckout.vue";
import { VTooltip } from "floating-vue";
import { mapGetters } from "vuex";
import ContributionsEnabledForProduct from "../../../Mixins/ContributionsEnabledForProduct";

// Slick Carrousel
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "ListProductCardWAdd",
  components: {
    ShopifyAddToCartButton,
    AlreadyBought,
    ProductRetailerLinks,
    LoadingButton,
    ModalSlider,
    ModalDialog,
    FluxRegaloReservaStep1,
    FluxRegaloReservaStep2,
    VueSlickCarousel,
    ListProductRegalar,
    ListProductRegalarCheckout,
  },
  directives: {
    tooltip: VTooltip,
  },
  mixins: [
    ContributionsEnabledForProduct,
  ],
  props: {
    list: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toggleMoreShops: false,
      componentKey: 0,
      showBookedModal: false,
      showBookedModalContent: false,
      error: false,
      email: "",
      loadingBooking: false,
      reservation: null,
      comprarYaClick: false,
      truncateComment: true,
      showImages: false,
      showComment: false,
      bookingStep: 1,
      showBookingContent: false,
      showBooking: false,
      isBlockedByReservation: false,
      showCancelContent: false,
      showCancel: false,
      loadingCancelBooking: false,
      showBookedUnlockedModal: false,
      showBookedModalUnlockedContent: false,
      focused: false,
      sliderPhotos: {
        arrows: true,
        // appendArrows: $(".modal-slider-carousel__arrows"),
        autoplay: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      currency: "myListCurrency",
    }),
    alreadyBought() {
      return this.product.contributionStatus === this.REGALADO;
    },
    SoldByHelloBB() {
      if (this.retailerIsHelloBB) return "Active";
      return "No";
    },
    id() {
      return this.getId(this.product);
    },
    productRetailers() {
      if (this.product.isFree) {
        return [
          {
            name: this.$utils.capitalize(this.product.retailerName),
            price: this.product.price,
          },
        ];
      }
      return (
        this.product.productRetailers?.slice(
          0,
          this.toggleMoreShops ? this.product.productRetailers?.length : 2
        ) || []
      );
    },
    statusColor() {
      return this.color[this.product.contributionStatus || 1];
    },
    showMoreShops() {
      return this.product.productRetailers?.length > 2;
    },
    moreShopsSimbol() {
      return this.toggleMoreShops ? "-" : "+";
    },
    retailerIsHelloBB() {
      return this.product.retailerName === "HelloBB" || this.product.shopifyId;
    },
    emailIsValid() {
      if (this.email === "") return false;
      return this.$utils.validateEmail(this.email);
    },
    emailHasError() {
      return !!(this.email !== "" && !this.$utils.validateEmail(this.email));
    },
    disabled() {
      return !this.$utils.validateEmail(this.email);
    },
    canShare() {
      return navigator.share && this.product.contributionStatus == this.PENDIENTE;
    },
    shouldShowMoreButton() {
      if (this.$mq === 'm992' || this.$mq === 'm1200') {
        return this.product.userComments.length > 70;
      }
      return this.product.userComments.length > 30;
    },
  },
  methods: {
    show() {
      this.showImages = true;
      this.showComment = true;
    },
    cancel() {
      this.showImages = false;
      this.showComment = false;
      this.$emit("cancel");
    },
    getImgUrl() {
      return process.env.URL_IMG_USER + this.list.photo;
    },
    getId(product) {
      return product.isFree ? "free_" + product.id : "catalogue_" + product.listProductId;
    },
    productSelected() {
      if (this.product.contributionStatus === this.REGALADO) return;
      if (
        this.product.contributionStatus === this.RESERVADO &&
        this.isBlockedByReservation
      ) {
        this.confirmBooking(false);
        return;
      }

      // named route with params to let the router build the url
      this.$router.push({
        name: "guestProduct",
        params: {
          id: this.list.id,
          productId: this.product.isFree ? this.product.id : this.product.listProductId,
          productType: this.product.isFree
            ? "free"
            : this.product.retailerName != "HelloBB"
            ? "catalog"
            : "hellobb",
        },
      });
    },
    reloadComponent() {
      this.componentKey += 1;
    },
    confirmBooking(comprarYa) {
      this.comprarYaClick = comprarYa;
      document.body.classList.add("modal-open");
      this.showBookedModal = true;
    },
    close() {
      this.showBookedModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
      //document.getElementById(this.getId(this.selectedProduct)).scrollIntoView();
    },
    closeUnlocked() {
      this.showBookedUnlockedModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    async checkEmail() {
      let result;
      if (this.product.isFree) {
        result = await this.$store.dispatch("checkEmailFreeProduct", {
          email: this.email,
          product: this.product,
        });
      } else {
        result = await this.$store.dispatch("checkEmail", {
          email: this.email,
          product: this.product,
        });
      }

      if (result?.data) {
        this.reservation = result.data;
        return true;
      }
      return false;
    },
    async next() {
      this.loadingBooking = true;
      let result = false;
      result = await this.checkEmail();
      this.loadingBooking = false;
      if (result) {
        if (this.comprarYaClick) {
          this.comprarYaClick = false;
          const comp = this.$refs.shopifyButtonRef;
          comp.openCheckoutUrl(true);
          this.error = false;
          document.body.classList.remove("modal-open");
          return;
        }

        this.error = false;
        document.body.classList.remove("modal-open");

        this.isBlockedByReservation = false;
        this.showBookedModal = false;

        this.showBookedUnlockedModal = true;

        return;

        this.$router.push({
          name: "guestProduct",
          params: {
            id: this.list.id,
            productId: this.product.isFree ? this.product.id : this.product.listProductId,
            //productType: this.selectedProduct.isFree ? "free" : "catalog",
            productType: this.product.isFree
              ? "free"
              : this.product.retailerName != "HelloBB"
              ? "catalog"
              : "hellobb",
          },
          query: this.reservation,
        });
      } else this.error = true;
    },
    openBooking() {
      console.log("openBooking");
      document.body.classList.add("modal-open");
      this.showBooking = true;
    },
    nextFirstStep() {
      //this.$emit("update");
      console.log("nextFirstStep");
      this.product.contributionStatus = this.RESERVADO;
      this.bookingStep++;
    },
    finishBooking() {
      this.showBookingContent = false;
      document.body.classList.remove("modal-open");
      this.isBlockedByReservation = true;
      console.log("finishBooking");
      this.bookingStep = 1;

      //this.$router.push({ name: "guest", params: { id: this.list.id } });
    },
    closeBooking() {
      this.showBooking = false;
      document.body.classList.remove("modal-open");
      console.log("closeBooking");
      this.$emit("close");
      console.log("closeBooking2");
      console.log(this.bookingStep);
      if (this.bookingStep === 2) {
        //this.$router.push({ name: "guest", params: { id: this.list.id } });
        //update list
        console.log("closeBooking3");
        this.$emit("updateList");
      } else if (this.bookingStep === 1) {
        console.log("closeBooking4");
        this.$emit("updateList");
      }
    },
    async shareProduct() {
      console.log("shareProduct");
      if (navigator.share) {
        const listid = this.list.id;
        const productId = this.product.isFree
          ? this.product.id
          : this.product.listProductId;
        const productType = this.product.isFree
          ? "free"
          : this.product.retailerName != "HelloBB"
          ? "catalog"
          : "hellobb";
        try {
          await navigator.share({
            title: this.product.name,
            text:
              "Mira lo que he visto en la lista de nacimiento de " + this.list.ownerName,
            url: `${process.env.APP_URL}/guest/${listid}/product/${productId}/${productType}`.replace(
              /([^:]\/)\/+/g,
              "$1"
            ),
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    closeCancel() {
      this.showCancel = false;
      document.body.classList.remove("modal-open");
      this.$emit("close");
    },
    openCancelBooking() {
      document.body.classList.add("modal-open");
      this.showCancel = true;
    },
    async cancelBooking() {
      try {
        this.loadingCancelBooking = true;
        await this.$store.dispatch("cancelBooking", {
          product: this.product,
          email: this.email,
          id: this.product.reservation.id,
        });
        this.loadingCancelBooking = false;
        this.showCancelContent = false;
        document.body.classList.remove("modal-open");
        this.$emit("updateList");
        // this.$sleep(
        //   () =>
        //     this.$router.push({ name: "guest", params: { id: this.list.id } }),
        //   1000,
        //   true
        // );

        window.mixpanel.identify(this.list.userId);
        window.mixpanel.track("Cancelar reserva", {
          "Object name": this.product.name,
          "Cancelled by": "Buyer",
        });
      } catch (error) {
        console.log(error);
      }
    },
    emitError(...args) {
      this.$emit("error", ...args);
    }
  },
  created() {
    this.PENDIENTE = 1;
    this.REGALADO = 2;
    this.RESERVADO = 3;
    this.statusText = {
      [this.PENDIENTE]: this.$t("generic.available"),
      [this.REGALADO]: this.$t("generic.purchased"),
      [this.RESERVADO]: this.$t("generic.reserved"),
    };
    this.color = {
      [this.PENDIENTE]: "",
      [this.REGALADO]: "badge--success",
      [this.RESERVADO]: "badge--accent",
    };
    this.isBlockedByReservation = this.product.contributionStatus === this.RESERVADO;
  },
};
</script>
